import {
  Box,
  Grid,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Heading,
} from '@chakra-ui/react';

function Dashboard() {
  return (
    <Box>
      <Heading mb={6}>Dashboard</Heading>
      <Grid templateColumns="repeat(auto-fit, minmax(200px, 1fr))" gap={6}>
        <Stat p={4} shadow="md" border="1px" borderColor="gray.200" rounded="md">
          <StatLabel>Total Users</StatLabel>
          <StatNumber>100</StatNumber>
          <StatHelpText>Feb 12 - Feb 28</StatHelpText>
        </Stat>
        <Stat p={4} shadow="md" border="1px" borderColor="gray.200" rounded="md">
          <StatLabel>Page Views</StatLabel>
          <StatNumber>1,500</StatNumber>
          <StatHelpText>Feb 12 - Feb 28</StatHelpText>
        </Stat>
        <Stat p={4} shadow="md" border="1px" borderColor="gray.200" rounded="md">
          <StatLabel>Conversion Rate</StatLabel>
          <StatNumber>15%</StatNumber>
          <StatHelpText>Feb 12 - Feb 28</StatHelpText>
        </Stat>
      </Grid>
    </Box>
  );
}

export default Dashboard; 