import { Box } from '@chakra-ui/react';
import Navbar from './Navbar';

function Layout({ children }) {
  return (
    <Box minH="100vh">
      <Navbar />
      <Box p={4}>
        {children}
      </Box>
    </Box>
  );
}

export default Layout; 