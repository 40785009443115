import { useState, useEffect } from 'react';
import {
    Box,
    Button,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    useDisclosure,
    Select,
    Stack,
    RadioGroup,
    Radio,
} from '@chakra-ui/react';
import axios from 'axios';
import DataTable from 'react-data-table-component';

function RefiraCustomers() {
    const [customers, setCustomers] = useState([]);
    const [refiras, setRefiras] = useState([]);
    const [selectedRefira, setSelectedRefira] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [newCustomer, setNewCustomer] = useState({
        refira_id: '',
        name: '',
        phone: '',
        status: true,
    });

    const fetchRefiras = async () => {
        try {
            const token = localStorage.getItem('token');
            const headers = { 'Authorization': `Basic ${token}` };
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v2/refira`, { headers });
            setRefiras(Array.isArray(response.data.data.items) ? response.data.data.items : []);
        } catch (error) {
            console.error('Error fetching refiras:', error);
            toast({
                title: 'Error fetching refiras',
                status: 'error',
                duration: 3000,
            });
        }
    };

    const fetchCustomers = async (refiraId) => {
        if (!refiraId) return;

        try {
            const token = localStorage.getItem('token');
            const headers = { 'Authorization': `Basic ${token}` };
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/api/v2/refira/${refiraId}/customers`,
                { headers }
            );
            setCustomers(Array.isArray(response.data.data.items) ? response.data.data.items : []);
        } catch (error) {
            console.error('Error fetching customers:', error);
            setCustomers([]);
            toast({
                title: 'Error fetching customers',
                status: 'error',
                duration: 3000,
            });
        }
    };

    const handleBulkUpload = async (e) => {
        e.preventDefault();
        if (!selectedFile || !selectedRefira) {
            toast({
                title: 'Please select a file and refira',
                status: 'error',
                duration: 3000,
            });
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const token = localStorage.getItem('token');
            const headers = {
                'Authorization': `Basic ${token}`,
                'Content-Type': 'multipart/form-data',
            };

            await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/api/v2/refira/${selectedRefira}/customers/bulk`,
                formData,
                { headers }
            );

            toast({
                title: 'Customers uploaded successfully',
                status: 'success',
                duration: 3000,
            });

            fetchCustomers(selectedRefira);
            onClose();
        } catch (error) {
            toast({
                title: 'Error uploading customers',
                description: error.response?.data?.message || 'Something went wrong',
                status: 'error',
                duration: 3000,
            });
        }
    };

    const handleCreateCustomer = async (e) => {
        e.preventDefault();

        try {
            const token = localStorage.getItem('token');
            const headers = {
                'Authorization': `Basic ${token}`,
                'Content-Type': 'application/json',
            };

            await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/api/v2/refira-customer`,
                { ...newCustomer, status: Boolean(newCustomer.status) },
                { headers }
            );

            toast({
                title: 'Customer created successfully',
                status: 'success',
                duration: 3000,
            });

            setIsCreateModalOpen(false);
            if (selectedRefira) {
                fetchCustomers(selectedRefira);
            }

            setNewCustomer({
                refira_id: '',
                name: '',
                phone: '',
                status: true,
            });
        } catch (error) {
            toast({
                title: 'Error creating customer',
                description: error.response?.data?.message || 'Something went wrong',
                status: 'error',
                duration: 3000,
            });
        }
    };

    useEffect(() => {
        fetchRefiras();
    }, []);

    useEffect(() => {
        if (selectedRefira) {
            fetchCustomers(selectedRefira);
        }
    }, [selectedRefira]);

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Phone',
            selector: row => row.phone,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status ? 'Active' : 'Inactive',
            sortable: true,
        },
        {
            name: 'Created At',
            selector: row => row.created_at,
            sortable: true,
            format: row => new Date(row.created_at).toLocaleString(),
        },
        {
            name: 'Updated At',
            selector: row => row.updated_at,
            sortable: true,
            format: row => row.updated_at ? new Date(row.updated_at).toLocaleString() : '-',
        },
    ];

    return (
        <Box p={4}>
            <Stack direction="row" spacing={4} mb={4}>
                <Select
                    placeholder="Select Refira"
                    value={selectedRefira}
                    onChange={(e) => setSelectedRefira(e.target.value)}
                    maxW="300px"
                >
                    {refiras.map(refira => (
                        <option key={refira._id} value={refira._id}>
                            {refira.name}
                        </option>
                    ))}
                </Select>

                <Button colorScheme="blue" onClick={onOpen}>
                    Upload Customers
                </Button>
                <Button colorScheme="green" onClick={() => setIsCreateModalOpen(true)}>
                    Create New Customer
                </Button>
            </Stack>

            <DataTable
                columns={columns}
                data={customers}
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                striped
                highlightOnHover
            />

            {/* Bulk Upload Modal */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Upload Customers</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <form onSubmit={handleBulkUpload}>
                            <Stack spacing={4}>
                                <FormControl isRequired>
                                    <FormLabel>Select Excel File</FormLabel>
                                    <Input
                                        type="file"
                                        accept=".xlsx,.xls"
                                        onChange={(e) => setSelectedFile(e.target.files[0])}
                                    />
                                </FormControl>

                                <Button mt={4} colorScheme="blue" type="submit">
                                    Upload
                                </Button>
                            </Stack>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>

            {/* Create Customer Modal */}
            <Modal isOpen={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)} size="lg">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create New Customer</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <form onSubmit={handleCreateCustomer}>
                            <Stack spacing={4}>
                                <FormControl isRequired>
                                    <FormLabel>Refira</FormLabel>
                                    <Select
                                        value={newCustomer.refira_id}
                                        onChange={(e) => setNewCustomer({
                                            ...newCustomer,
                                            refira_id: e.target.value
                                        })}
                                    >
                                        <option value="">Select Refira</option>
                                        {refiras.map(refira => (
                                            <option key={refira._id} value={refira._id}>
                                                {refira.name}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl isRequired>
                                    <FormLabel>Name</FormLabel>
                                    <Input
                                        value={newCustomer.name}
                                        onChange={(e) => setNewCustomer({
                                            ...newCustomer,
                                            name: e.target.value
                                        })}
                                    />
                                </FormControl>

                                <FormControl isRequired>
                                    <FormLabel>Phone</FormLabel>
                                    <Input
                                        value={newCustomer.phone}
                                        onChange={(e) => setNewCustomer({
                                            ...newCustomer,
                                            phone: e.target.value
                                        })}
                                    />
                                </FormControl>

                                <FormControl isRequired>
                                    <FormLabel>Status</FormLabel>
                                    <RadioGroup
                                        value={newCustomer.status.toString()}
                                        onChange={(value) => setNewCustomer({
                                            ...newCustomer,
                                            status: value === 'true'
                                        })}
                                    >
                                        <Stack direction="row">
                                            <Radio value="true">Active</Radio>
                                            <Radio value="false">Inactive</Radio>
                                        </Stack>
                                    </RadioGroup>
                                </FormControl>

                                <Button mt={4} colorScheme="blue" type="submit">
                                    Create Customer
                                </Button>
                            </Stack>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    );
}

export default RefiraCustomers; 