import { useState, useEffect } from 'react';
import {
    Box,
    Button,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormControl,
    FormLabel,
    Input,
    Switch,
    useDisclosure,
} from '@chakra-ui/react';
import axios from 'axios';
import DataTable from 'react-data-table-component';

function EreceiptConfig() {
    const [configs, setConfigs] = useState([]);
    const [currentConfig, setCurrentConfig] = useState({
        name: '',
        whatsapp_number: '',
        whatsapp_token: '',
        status: true
    });
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const fetchConfigs = async () => {
        try {
            const token = localStorage.getItem('token');
            const headers = {
                'Authorization': `Basic ${token}`
            };

            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v2/ereceipt-config`, { headers });
            setConfigs(Array.isArray(response.data.data.items) ? response.data.data.items : []);
        } catch (error) {
            toast({
                title: 'Error fetching configs',
                status: 'error',
                duration: 3000,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');

            const headers = {
                'Authorization': `Basic ${token}`
            };

            const payload = {
                name: currentConfig.name,
                whatsapp_number: currentConfig.whatsapp_number,
                whatsapp_token: currentConfig.whatsapp_token,
                status: currentConfig.status
            };

            if (currentConfig._id) {
                console.log('update');
                await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/v2/ereceipt-config/${currentConfig._id}`, payload, { headers });
            } else {
                await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v2/ereceipt-config`, payload, { headers });
            }
            onClose();
            fetchConfigs();
            toast({
                title: 'Config saved successfully',
                status: 'success',
                duration: 3000,
            });
        } catch (error) {
            toast({
                title: 'Error saving config',
                description: error.response?.data?.message || 'An error occurred',
                status: 'error',
                duration: 3000,
            });
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this config?')) {
            try {
                const token = localStorage.getItem('token');

                const headers = {
                    'Authorization': `Basic ${token}`
                };

                await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/v2/ereceipt-config/${id}`, { headers });
                fetchConfigs();
                toast({
                    title: 'Config deleted successfully',
                    status: 'success',
                    duration: 3000,
                });
            } catch (error) {
                toast({
                    title: 'Error deleting config',
                    status: 'error',
                    duration: 3000,
                });
            }
        }
    };

    const columns = [
        {
            name: 'ID',
            selector: row => row._id,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'WhatsApp Number',
            selector: row => row.whatsapp_number,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            cell: row => (
                <Switch
                    isChecked={row.status}
                    isReadOnly
                    colorScheme="green"
                />
            ),
        },
        {
            name: 'Created At',
            selector: row => row.created_at,
            sortable: true,
            cell: row => new Date(row.created_at).toLocaleString(),
        },
        {
            name: 'Actions',
            cell: row => (
                <>
                    <Button
                        size="sm"
                        colorScheme="yellow"
                        mr={2}
                        onClick={() => {
                            setCurrentConfig(row);
                            onOpen();
                        }}
                    >
                        Edit
                    </Button>
                    <Button
                        size="sm"
                        colorScheme="red"
                        onClick={() => handleDelete(row.id)}
                    >
                        Delete
                    </Button>
                </>
            ),
        },
    ];

    useEffect(() => {
        fetchConfigs();
    }, []);

    return (
        <Box p={4}>
            <Button colorScheme="blue" mb={4} onClick={() => {
                setCurrentConfig({
                    name: '',
                    whatsapp_number: '',
                    whatsapp_token: '',
                    status: true
                });
                onOpen();
            }}>
                Add New Config
            </Button>

            <DataTable
                columns={columns}
                data={configs}
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50]}
                onChangePage={page => fetchConfigs(page)}
                onChangeRowsPerPage={pageSize => fetchConfigs(1)}
                paginationTotalRows={configs.length}
            />

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        {currentConfig.id ? 'Edit Config' : 'Add New Config'}
                    </ModalHeader>
                    <ModalBody>
                        <form onSubmit={handleSubmit}>
                            <FormControl isRequired>
                                <FormLabel>Name</FormLabel>
                                <Input
                                    value={currentConfig.name}
                                    onChange={(e) =>
                                        setCurrentConfig({ ...currentConfig, name: e.target.value })
                                    }
                                />
                            </FormControl>
                            <FormControl mt={4} isRequired>
                                <FormLabel>WhatsApp Number</FormLabel>
                                <Input
                                    value={currentConfig.whatsapp_number}
                                    onChange={(e) =>
                                        setCurrentConfig({ ...currentConfig, whatsapp_number: e.target.value })
                                    }
                                    placeholder="e.g., 6281294047444"
                                />
                            </FormControl>
                            <FormControl mt={4}>
                                <FormLabel>WhatsApp Token</FormLabel>
                                <Input
                                    value={currentConfig.whatsapp_token}
                                    onChange={(e) =>
                                        setCurrentConfig({ ...currentConfig, whatsapp_token: e.target.value })
                                    }
                                    type="password"
                                />
                            </FormControl>
                            <FormControl mt={4} display="flex" alignItems="center">
                                <FormLabel mb="0">Status</FormLabel>
                                <Switch
                                    isChecked={currentConfig.status}
                                    onChange={(e) =>
                                        setCurrentConfig({ ...currentConfig, status: e.target.checked })
                                    }
                                    colorScheme="green"
                                />
                            </FormControl>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
                            Save
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
}

export default EreceiptConfig; 