import { useState, useEffect } from 'react';
import {
    Box,
    Button,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    useDisclosure,
    Stack,
    Select,
    Switch,
} from '@chakra-ui/react';
import axios from 'axios';
import DataTable from 'react-data-table-component';

function Users() {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const fetchUsers = async () => {
        try {
            const token = localStorage.getItem('token');
            const headers = {
                'Authorization': `Basic ${token}`
            };
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v2/users`, { headers });
            setUsers(Array.isArray(response.data.data.items) ? response.data.data.items : []);
        } catch (error) {
            console.error('Error fetching users:', error);
            setUsers([]);
            toast({
                title: 'Error fetching users',
                status: 'error',
                duration: 3000,
            });
        }
    };

    const handleCreate = async (formData) => {
        try {
            const token = localStorage.getItem('token');
            const headers = {
                'Authorization': `Basic ${token}`
            };
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v2/users`, formData, { headers });
            fetchUsers();
            onClose();
            toast({
                title: 'User created successfully',
                status: 'success',
                duration: 3000,
            });
        } catch (error) {
            toast({
                title: 'Error creating user',
                status: 'error',
                duration: 3000,
            });
        }
    };

    const handleUpdate = async (id, formData) => {
        try {
            const token = localStorage.getItem('token');
            const headers = {
                'Authorization': `Basic ${token}`
            };
            await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/api/v2/users/${id}`, formData, { headers });
            fetchUsers();
            onClose();
            toast({
                title: 'User updated successfully',
                status: 'success',
                duration: 3000,
            });
        } catch (error) {
            toast({
                title: 'Error updating user',
                status: 'error',
                duration: 3000,
            });
        }
    };

    const handleDelete = async (id) => {
        try {
            const token = localStorage.getItem('token');
            const headers = {
                'Authorization': `Basic ${token}`
            };
            await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/v2/users/${id}`, { headers });
            fetchUsers();
            toast({
                title: 'User deleted successfully',
                status: 'success',
                duration: 3000,
            });
        } catch (error) {
            toast({
                title: 'Error deleting user',
                status: 'error',
                duration: 3000,
            });
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const columns = [
        {
            name: 'ID',
            selector: row => row._id,
            sortable: true,
        },
        {
            name: 'Username',
            selector: row => row.username,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Role',
            selector: row => row.role,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status ? 'Active' : 'Inactive',
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => (
                <>
                    <Button
                        size="sm"
                        colorScheme="yellow"
                        mr={2}
                        onClick={() => {
                            setSelectedUser(row);
                            onOpen();
                        }}
                    >
                        Edit
                    </Button>
                    <Button
                        size="sm"
                        colorScheme="red"
                        onClick={() => handleDelete(row._id)}
                    >
                        Delete
                    </Button>
                </>
            ),
        },
    ];

    return (
        <Box p={4}>
            <Button colorScheme="blue" mb={4} onClick={() => {
                setSelectedUser(null);
                onOpen();
            }}>
                Create New User
            </Button>

            <DataTable
                columns={columns}
                data={users}
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                striped
                highlightOnHover
            />

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        {selectedUser ? 'Edit User' : 'Create New User'}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <UserForm
                            user={selectedUser}
                            onSubmit={(formData) => {
                                if (selectedUser) {
                                    handleUpdate(selectedUser._id, formData);
                                } else {
                                    handleCreate(formData);
                                }
                            }}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    );
}

function UserForm({ user, onSubmit }) {
    const [formData, setFormData] = useState({
        username: user?.username || '',
        email: user?.email || '',
        password: '',
        role: user?.role || 'user',
        status: user?.status ?? true,
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={4}>
                <FormControl>
                    <FormLabel>Username</FormLabel>
                    <Input
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        required
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Password {user && '(Leave blank to keep current password)'}</FormLabel>
                    <Input
                        name="password"
                        type="password"
                        value={formData.password}
                        onChange={handleChange}
                        required={!user}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Role</FormLabel>
                    <Select
                        name="role"
                        value={formData.role}
                        onChange={handleChange}
                    >

                        <option value="admin">Admin</option>
                        <option value="user">Dashboard User</option>
                        <option value="api">API User</option>
                    </Select>
                </FormControl>

                <FormControl display="flex" alignItems="center">
                    <FormLabel mb="0">Status</FormLabel>
                    <Switch
                        name="status"
                        isChecked={formData.status}
                        onChange={handleChange}
                    />
                </FormControl>

                <Button mt={4} colorScheme="blue" type="submit">
                    {user ? 'Update' : 'Create'}
                </Button>
            </Stack>
        </form>
    );
}

export default Users; 