import { useState, useEffect } from 'react';
import {
    Box,
    Button,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    Link,
    Text,
    Image,
    Center,
    Spinner,
} from '@chakra-ui/react';
import axios from 'axios';
import DataTable from 'react-data-table-component';

function EreceiptTransaction() {
    const [transactions, setTransactions] = useState([]);
    const [selectedReceipt, setSelectedReceipt] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isImageLoading, setIsImageLoading] = useState(false);
    const toast = useToast();

    const fetchTransactions = async () => {
        try {
            const token = localStorage.getItem('token');
            const headers = {
                'Authorization': `Basic ${token}`
            };

            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v2/ereceipt`, { headers });
            setTransactions(Array.isArray(response.data.data.items) ? response.data.data.items : []);
        } catch (error) {
            toast({
                title: 'Error fetching transactions',
                description: error.response?.data?.message || 'An error occurred',
                status: 'error',
                duration: 3000,
            });
        }
    };

    const handleViewReceipt = (url) => {
        setSelectedReceipt(url);
        setIsModalOpen(true);
        setIsImageLoading(true);
    };

    const handleImageLoad = () => {
        setIsImageLoading(false);
    };

    const handleImageError = () => {
        setIsImageLoading(false);
        toast({
            title: 'Error loading image',
            description: 'Unable to load the receipt image',
            status: 'error',
            duration: 3000,
        });
    };

    const columns = [
        {
            name: 'Transaction ID',
            selector: row => row._id,
            sortable: true,
        },
        {
            name: 'Company',
            selector: row => row.company,
            sortable: true,
        },
        {
            name: 'Trxid',
            selector: row => row.trxid,
            sortable: true,
        },
        {
            name: 'Amount',
            selector: row => row.amount,
            sortable: true,
            cell: row => `Rp ${row.amount?.toLocaleString('id-ID') || 0}`,
        },
        {
            name: 'Created By',
            selector: row => row.created_by,
            sortable: true,
        },
        {
            name: 'Created At',
            selector: row => row.created_at,
            sortable: true,
            cell: row => new Date(row.created_at).toLocaleString(),
        },
        {
            name: 'eReceipt',
            cell: row => (
                <Button
                    size="sm"
                    colorScheme="blue"
                    onClick={() => handleViewReceipt(row.url)}
                    isDisabled={!row.url}
                >
                    View Receipt
                </Button>
            ),
        }
    ];

    useEffect(() => {
        fetchTransactions();
    }, []);

    return (
        <Box p={4}>
            <Button
                colorScheme="blue"
                mb={4}
                onClick={fetchTransactions}
            >
                Refresh Data
            </Button>

            <DataTable
                columns={columns}
                data={Array.isArray(transactions) ? transactions : []}
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                striped
                highlightOnHover
            />

            <Modal
                isOpen={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                    setIsImageLoading(false);
                }}
                size="4xl"
                isCentered
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>eReceipt Image</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {selectedReceipt ? (
                            <Box>
                                <Center position="relative" minH="200px">
                                    {isImageLoading && (
                                        <Center position="absolute" top="0" left="0" right="0" bottom="0">
                                            <Spinner size="xl" />
                                        </Center>
                                    )}
                                    <Image
                                        src={selectedReceipt}
                                        alt="eReceipt"
                                        maxH="70vh"
                                        objectFit="contain"
                                        onLoad={handleImageLoad}
                                        onError={handleImageError}
                                        display={isImageLoading ? 'none' : 'block'}
                                    />
                                </Center>
                                <Text mt={4} fontSize="sm" color="gray.500" wordBreak="break-all">
                                    URL: {selectedReceipt}
                                </Text>
                                <Link
                                    href={selectedReceipt}
                                    isExternal
                                    color="blue.500"
                                    textDecoration="underline"
                                    display="block"
                                    mt={2}
                                >
                                    Open in new tab
                                </Link>
                            </Box>
                        ) : (
                            <Text>No receipt image available</Text>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            colorScheme="blue"
                            onClick={() => {
                                setIsModalOpen(false);
                                setIsImageLoading(false);
                            }}
                        >
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
}

export default EreceiptTransaction; 