import React, { useState, useEffect } from 'react';
import axios from 'axios';

import {
    Box,
    Button,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    useDisclosure,
    Select,
    Stack,
    RadioGroup,
    Radio,
    Textarea,
    Switch,
} from '@chakra-ui/react';
import DataTable from 'react-data-table-component';

function Refira() {
    const [refiras, setRefiras] = useState([]);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [selectedRefira, setSelectedRefira] = useState(null);
    const toast = useToast();

    const fetchRefiras = async () => {
        try {
            const token = localStorage.getItem('token');
            const headers = { 'Authorization': `Basic ${token}` };
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v2/refira/`, { headers });
            setRefiras(Array.isArray(response.data.data.items) ? response.data.data.items : []);
        } catch (error) {
            console.error('Error fetching refiras:', error);
            setRefiras([]);
            toast({
                title: 'Error fetching refiras',
                status: 'error',
                duration: 3000,
            });
        }
    };

    const handleCreateOrUpdate = async (formData) => {
        try {
            const token = localStorage.getItem('token');
            const headers = {
                'Authorization': `Basic ${token}`,
                'Content-Type': 'application/json',
            };

            if (selectedRefira) {
                await axios.put(
                    `${process.env.REACT_APP_API_BASE_URL}/api/v2/refira/${selectedRefira._id}`,
                    formData,
                    { headers }
                );
                toast({
                    title: 'Refira updated successfully',
                    status: 'success',
                    duration: 3000,
                });
            } else {
                await axios.post(
                    `${process.env.REACT_APP_API_BASE_URL}/api/v2/refira`,
                    formData,
                    { headers }
                );
                toast({
                    title: 'Refira created successfully',
                    status: 'success',
                    duration: 3000,
                });
            }

            setIsCreateModalOpen(false);
            setSelectedRefira(null);
            fetchRefiras();
        } catch (error) {
            toast({
                title: 'Error saving refira',
                description: error.response?.data?.message || 'Something went wrong',
                status: 'error',
                duration: 3000,
            });
        }
    };

    const handleDelete = async (id) => {
        if (!window.confirm('Are you sure you want to delete this refira?')) return;

        try {
            const token = localStorage.getItem('token');
            const headers = { 'Authorization': `Basic ${token}` };
            await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/v2/refira/${id}`, { headers });

            toast({
                title: 'Refira deleted successfully',
                status: 'success',
                duration: 3000,
            });
            fetchRefiras();
        } catch (error) {
            toast({
                title: 'Error deleting refira',
                status: 'error',
                duration: 3000,
            });
        }
    };

    useEffect(() => {
        fetchRefiras();
    }, []);

    const columns = [
        {
            name: 'ID',
            selector: row => row._id,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'WhatsApp Number',
            selector: row => row.whatsapp_number,
            sortable: true,
        },
        {
            name: 'Agent Open',
            selector: row => row.agent_open ? 'Yes' : 'No',
            sortable: true,
        },
        {
            name: 'Customer Open',
            selector: row => row.customer_open ? 'Yes' : 'No',
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status ? 'Active' : 'Inactive',
            sortable: true,
        },
        {
            name: 'Started At',
            selector: row => row.started_at,
            sortable: true,
        },
        {
            name: 'Ended At',
            selector: row => row.ended_at,
            sortable: true,
        },
        {
            name: 'Created At',
            selector: row => row.created_at,
            sortable: true,
        },
        {
            name: 'Updated At',
            selector: row => row.updated_at,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => (
                <Stack direction="row" spacing={2}>
                    <Button
                        size="sm"
                        colorScheme="blue"
                        onClick={() => {
                            setSelectedRefira(row);
                            setIsCreateModalOpen(true);
                        }}
                    >
                        Edit
                    </Button>
                    <Button
                        size="sm"
                        colorScheme="red"
                        onClick={() => handleDelete(row._id)}
                    >
                        Delete
                    </Button>
                </Stack>
            ),
        },
    ];

    return (
        <Box p={4}>
            <Stack direction="row" spacing={4} mb={4}>
                <Button
                    colorScheme="green"
                    onClick={() => {
                        setSelectedRefira(null);
                        setIsCreateModalOpen(true);
                    }}
                >
                    Create New Refira
                </Button>
            </Stack>

            <DataTable
                columns={columns}
                data={refiras}
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                striped
                highlightOnHover
            />

            <Modal
                isOpen={isCreateModalOpen}
                onClose={() => {
                    setIsCreateModalOpen(false);
                    setSelectedRefira(null);
                }}
                size="xl"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        {selectedRefira ? 'Edit Refira' : 'Create New Refira'}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <RefiraForm
                            refira={selectedRefira}
                            onSubmit={handleCreateOrUpdate}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    );
}

function RefiraForm({ refira, onSubmit }) {
    const [formData, setFormData] = useState({
        name: refira?.name || '',
        whatsapp_number: refira?.whatsapp_number || '',
        whatsapp_token: refira?.whatsapp_token || '',
        agent_image_url: refira?.agent_image_url || '',
        voucher_generate_image_url: refira?.voucher_generate_image_url || '',
        subscribe_image_url: refira?.subscribe_image_url || '',
        agent_open: refira?.agent_open ?? true,
        agent_validate: refira?.agent_validate || 'database',
        agent_validate_url: refira?.agent_validate_url || '',
        max_redeem: refira?.max_redeem || 0,
        max_redeem_interval: refira?.max_redeem_interval || 0,
        voucher_generate: refira?.voucher_generate || 'database',
        voucher_generate_url: refira?.voucher_generate_url || '',
        customer_open: refira?.customer_open ?? true,
        customer_validate: refira?.customer_validate || 'database',
        customer_validate_url: refira?.customer_validate_url || '',
        agent_redeem: refira?.agent_redeem ?? false,
        voucher_generate_message: refira?.voucher_generate_message || '',
        subscribe_message: refira?.subscribe_message || '',
        voucher_redeem_callback: refira?.voucher_redeem_callback ?? false,
        agent_referral_notification_message: refira?.agent_referral_notification_message || '',
        voucher_redeem_notification_message: refira?.voucher_redeem_notification_message || '',
        agent_cashback_type: refira?.agent_cashback_type || 'percentage',
        agent_cashback_value: refira?.agent_cashback_value || 0,
        customer_cashback_type: refira?.customer_cashback_type || 'percentage',
        customer_cashback_value: refira?.customer_cashback_value || 0,
        status: refira?.status ?? true,
        started_at: refira?.started_at || '',
        ended_at: refira?.ended_at || '',
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={4}>
                {/* Basic Information */}
                <FormControl isRequired>
                    <FormLabel>Name</FormLabel>
                    <Input
                        name="name"
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    />
                </FormControl>

                <FormControl isRequired>
                    <FormLabel>WhatsApp Number</FormLabel>
                    <Input
                        name="whatsapp_number"
                        value={formData.whatsapp_number}
                        onChange={(e) => setFormData({ ...formData, whatsapp_number: e.target.value })}
                    />
                </FormControl>

                <FormControl isRequired>
                    <FormLabel>WhatsApp Token</FormLabel>
                    <Input
                        name="whatsapp_token"
                        value={formData.whatsapp_token}
                        onChange={(e) => setFormData({ ...formData, whatsapp_token: e.target.value })}
                    />
                </FormControl>

                {/* URLs */}
                <FormControl>
                    <FormLabel>Agent Image URL</FormLabel>
                    <Input
                        name="agent_image_url"
                        value={formData.agent_image_url}
                        onChange={(e) => setFormData({ ...formData, agent_image_url: e.target.value })}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Voucher Generate Image URL</FormLabel>
                    <Input
                        name="voucher_generate_image_url"
                        value={formData.voucher_generate_image_url}
                        onChange={(e) => setFormData({ ...formData, voucher_generate_image_url: e.target.value })}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Subscribe Image URL</FormLabel>
                    <Input
                        name="subscribe_image_url"
                        value={formData.subscribe_image_url}
                        onChange={(e) => setFormData({ ...formData, subscribe_image_url: e.target.value })}
                    />
                </FormControl>

                {/* Agent Settings */}
                <FormControl display="flex" alignItems="center">
                    <FormLabel mb="0">Agent Open</FormLabel>
                    <Switch
                        isChecked={formData.agent_open}
                        onChange={(e) => setFormData({ ...formData, agent_open: e.target.checked })}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Agent Validate</FormLabel>
                    <Select
                        value={formData.agent_validate}
                        onChange={(e) => setFormData({ ...formData, agent_validate: e.target.value })}
                    >
                        <option value="database">Database</option>
                        <option value="api">API</option>
                    </Select>
                </FormControl>

                {formData.agent_validate === 'api' && (
                    <FormControl>
                        <FormLabel>Agent Validate URL</FormLabel>
                        <Input
                            value={formData.agent_validate_url}
                            onChange={(e) => setFormData({ ...formData, agent_validate_url: e.target.value })}
                        />
                    </FormControl>
                )}

                <FormControl>
                    <FormLabel>Max Redeem</FormLabel>
                    <Input
                        type="number"
                        value={formData.max_redeem}
                        onChange={(e) => setFormData({ ...formData, max_redeem: parseInt(e.target.value) })}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Max Redeem Interval</FormLabel>
                    <Input
                        type="number"
                        value={formData.max_redeem_interval}
                        onChange={(e) => setFormData({ ...formData, max_redeem_interval: parseInt(e.target.value) })}
                    />
                </FormControl>

                {/* Voucher Generation */}
                <FormControl>
                    <FormLabel>Voucher Generate</FormLabel>
                    <Select
                        value={formData.voucher_generate}
                        onChange={(e) => setFormData({ ...formData, voucher_generate: e.target.value })}
                    >
                        <option value="database">Database</option>
                        <option value="api">API</option>
                    </Select>
                </FormControl>

                {formData.voucher_generate === 'api' && (
                    <FormControl>
                        <FormLabel>Voucher Generate URL</FormLabel>
                        <Input
                            value={formData.voucher_generate_url}
                            onChange={(e) => setFormData({ ...formData, voucher_generate_url: e.target.value })}
                        />
                    </FormControl>
                )}

                {/* Customer Settings */}
                <FormControl display="flex" alignItems="center">
                    <FormLabel mb="0">Customer Open</FormLabel>
                    <Switch
                        isChecked={formData.customer_open}
                        onChange={(e) => setFormData({ ...formData, customer_open: e.target.checked })}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Customer Validate</FormLabel>
                    <Select
                        value={formData.customer_validate}
                        onChange={(e) => setFormData({ ...formData, customer_validate: e.target.value })}
                    >
                        <option value="database">Database</option>
                        <option value="api">API</option>
                    </Select>
                </FormControl>

                {formData.customer_validate === 'api' && (
                    <FormControl>
                        <FormLabel>Customer Validate URL</FormLabel>
                        <Input
                            value={formData.customer_validate_url}
                            onChange={(e) => setFormData({ ...formData, customer_validate_url: e.target.value })}
                        />
                    </FormControl>
                )}

                {/* Additional Settings */}
                <FormControl display="flex" alignItems="center">
                    <FormLabel mb="0">Agent Redeem</FormLabel>
                    <Switch
                        isChecked={formData.agent_redeem}
                        onChange={(e) => setFormData({ ...formData, agent_redeem: e.target.checked })}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Voucher Generate Message</FormLabel>
                    <Textarea
                        value={formData.voucher_generate_message}
                        onChange={(e) => setFormData({ ...formData, voucher_generate_message: e.target.value })}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Subscribe Message</FormLabel>
                    <Textarea
                        value={formData.subscribe_message}
                        onChange={(e) => setFormData({ ...formData, subscribe_message: e.target.value })}
                    />
                </FormControl>

                <FormControl display="flex" alignItems="center">
                    <FormLabel mb="0">Voucher Redeem Callback</FormLabel>
                    <Switch
                        isChecked={formData.voucher_redeem_callback}
                        onChange={(e) => setFormData({ ...formData, voucher_redeem_callback: e.target.checked })}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Agent Referral Notification Message</FormLabel>
                    <Textarea
                        value={formData.agent_referral_notification_message}
                        onChange={(e) => setFormData({ ...formData, agent_referral_notification_message: e.target.value })}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Voucher Redeem Notification Message</FormLabel>
                    <Textarea
                        value={formData.voucher_redeem_notification_message}
                        onChange={(e) => setFormData({ ...formData, voucher_redeem_notification_message: e.target.value })}
                    />
                </FormControl>

                {/* Cashback Settings */}
                <FormControl>
                    <FormLabel>Agent Cashback Type</FormLabel>
                    <Select
                        value={formData.agent_cashback_type}
                        onChange={(e) => setFormData({ ...formData, agent_cashback_type: e.target.value })}
                    >
                        <option value="percentage">Percentage</option>
                        <option value="amount">Amount</option>
                    </Select>
                </FormControl>

                <FormControl>
                    <FormLabel>Agent Cashback Value</FormLabel>
                    <Input
                        type="number"
                        value={formData.agent_cashback_value}
                        onChange={(e) => setFormData({ ...formData, agent_cashback_value: parseFloat(e.target.value) })}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Customer Cashback Type</FormLabel>
                    <Select
                        value={formData.customer_cashback_type}
                        onChange={(e) => setFormData({ ...formData, customer_cashback_type: e.target.value })}
                    >
                        <option value="percentage">Percentage</option>
                        <option value="amount">Amount</option>
                    </Select>
                </FormControl>

                <FormControl>
                    <FormLabel>Customer Cashback Value</FormLabel>
                    <Input
                        type="number"
                        value={formData.customer_cashback_value}
                        onChange={(e) => setFormData({ ...formData, customer_cashback_value: parseFloat(e.target.value) })}
                    />
                </FormControl>

                {/* Status */}
                <FormControl display="flex" alignItems="center">
                    <FormLabel mb="0">Status</FormLabel>
                    <Switch
                        isChecked={formData.status}
                        onChange={(e) => setFormData({ ...formData, status: e.target.checked })}
                    />
                </FormControl>

                {/* Dates */}
                <FormControl isRequired>
                    <FormLabel>Start Date</FormLabel>
                    <Input
                        type="datetime-local"
                        value={formData.started_at}
                        onChange={(e) => setFormData({ ...formData, started_at: e.target.value })}
                    />
                </FormControl>

                <FormControl isRequired>
                    <FormLabel>End Date</FormLabel>
                    <Input
                        type="datetime-local"
                        value={formData.ended_at}
                        onChange={(e) => setFormData({ ...formData, ended_at: e.target.value })}
                    />
                </FormControl>

                <Button mt={4} colorScheme="blue" type="submit">
                    {refira ? 'Update' : 'Create'}
                </Button>
            </Stack>
        </form>
    );
}

export default Refira; 