import { useState, useEffect } from 'react';
import {
    Box,
    useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import DataTable from 'react-data-table-component';

function KaddoTransactions() {
    const [transactions, setTransactions] = useState([]);
    const toast = useToast();

    const fetchTransactions = async () => {
        try {
            const token = localStorage.getItem('token');
            const headers = {
                'Authorization': `Basic ${token}`
            };

            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v2/kaddo-transactions/`, { headers });
            setTransactions(Array.isArray(response.data.data.items) ? response.data.data.items : []);
        } catch (error) {
            console.error('Error fetching transactions:', error);
            setTransactions([]);
            toast({
                title: 'Error fetching transactions',
                status: 'error',
                duration: 3000,
            });
        }
    };

    useEffect(() => {
        fetchTransactions();
    }, []);

    const columns = [
        {
            name: 'ID',
            selector: row => row._id,
            sortable: true,
        },
        {
            name: 'Kaddo Name',
            selector: row => row.kaddo_name,
            sortable: true,
        },
        {
            name: 'Customer Name',
            selector: row => row.customer_name,
            sortable: true,
        },
        {
            name: 'Phone Number',
            selector: row => row.phone_number,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
        },
        {
            name: 'Created At',
            selector: row => row.created_at,
            sortable: true,
        }
    ];

    return (
        <Box p={4}>
            <DataTable
                columns={columns}
                data={Array.isArray(transactions) ? transactions : []}
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                striped
                highlightOnHover
            />
        </Box>
    );
}

export default KaddoTransactions; 