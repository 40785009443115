import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Register from './pages/Register';
import ProtectedRoute from './components/ProtectedRoute';
import Kaddos from './pages/Kaddos';
import EreceiptConfig from './pages/EreceiptConfig';
import EreceiptTransaction from './pages/EreceiptTransaction';
import Users from './pages/Users';
import KaddoTransactions from './pages/KaddoTransactions';
import KaddoVouchers from './pages/KaddoVouchers';
import Refira from './pages/Refira';
import RefiraTransactions from './pages/RefiraTransactions';
import RefiraVouchers from './pages/RefiraVouchers';
import RefiraAgents from './pages/RefiraAgents';
import RefiraCustomers from './pages/RefiraCustomers';

function PrivateRoute({ children }) {
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  return isAuthenticated ? children : <Navigate to="/login" />;
}

function App() {
  return (
    <ChakraProvider>
      <Router>
        <Layout>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route 
              path="/dashboard" 
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/" 
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/kaddos" 
              element={
                <ProtectedRoute>
                  <Kaddos />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/ereceipt-config" 
              element={
                <ProtectedRoute>
                  <EreceiptConfig />
                </ProtectedRoute>
              } 
            />
            <Route
              path="/ereceipt-transactions"
              element={
                <ProtectedRoute>
                  <EreceiptTransaction />
                </ProtectedRoute>
              }
            />
            <Route 
              path="/users" 
              element={
                <ProtectedRoute>
                  <Users />
                </ProtectedRoute>
              } 
            />
            <Route path="/kaddo-transactions" element={<KaddoTransactions />} />
            <Route path="/kaddo-vouchers" element={<KaddoVouchers />} />
            <Route 
              path="/refiras" 
              element={
                <ProtectedRoute>
                  <Refira />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/refira-transactions" 
              element={
                <ProtectedRoute>
                  <RefiraTransactions />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/refira-vouchers" 
              element={
                <ProtectedRoute>
                  <RefiraVouchers />
                </ProtectedRoute>
              } 
            />
            <Route path="/refira-agents" element={<RefiraAgents />} />
            <Route path="/refira-customers" element={<RefiraCustomers />} />
          </Routes>
        </Layout>
      </Router>
    </ChakraProvider>
  );
}

export default App; 