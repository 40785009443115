import {
  Box,
  Flex,
  IconButton,
  useDisclosure,
  Stack,
  Link,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { HamburgerIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { FiUsers } from 'react-icons/fi';

function Navbar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';

  const Links = isAuthenticated
    ? [
      { name: 'Dashboard', path: '/dashboard' },
      {
        group: 'Kaddo', links: [
          { name: 'Kaddo Config', path: '/kaddos' },
          { name: 'Kaddo Transactions', path: '/kaddo-transactions' },
          { name: 'Kaddo Vouchers', path: '/kaddo-vouchers' },
        ]
      },
      {
        group: 'eReceipt', links: [
          { name: 'eReceipt Config', path: '/ereceipt-config' },
          { name: 'eReceipt Transactions', path: '/ereceipt-transactions' },
        ]
      },
      {
        group: 'Refira', links: [
          { name: 'Refira Config', path: '/refiras' },
          { name: 'Refira Transactions', path: '/refira-transactions' },
          { name: 'Refira Vouchers', path: '/refira-vouchers' },
          { name: 'Refira Agents', path: '/refira-agents' },
          { name: 'Refira Customers', path: '/refira-customers' },
        ]
      },
      { name: 'Users', path: '/users' },
    ]
    : [
      { name: 'Login', path: '/login' },
      { name: 'Register', path: '/register' },
    ];

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('isAuthenticated');
    navigate('/login');
  };

  const renderLinks = (links, onClickHandler = null) => {
    return links.map((item) => {
      if (item.group) {
        return (
          <Box key={item.group}>
            <Box color="gray.500" fontSize="sm" mb={2}>{item.group}</Box>
            <Stack pl={4} spacing={2}>
              {item.links.map((link) => (
                <Link
                  key={link.name}
                  as={RouterLink}
                  to={link.path}
                  p={2}
                  rounded="md"
                  _hover={{ bg: 'gray.600', color: 'white' }}
                  color="black"
                  onClick={onClickHandler}
                >
                  {link.name}
                </Link>
              ))}
            </Stack>
          </Box>
        );
      }
      return (
        <Link
          key={item.name}
          as={RouterLink}
          to={item.path}
          p={2}
          rounded="md"
          _hover={{ bg: 'gray.600', color: 'white' }}
          color="black"
          onClick={onClickHandler}
        >
          {item.name}
        </Link>
      );
    });
  };

  const renderDesktopLinks = (links) => {
    return links.map((item) => {
      if (item.group) {
        return (
          <Menu key={item.group}>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              variant="ghost"
              color="white"
              _hover={{ bg: 'gray.600', color: 'white' }}
              _active={{ bg: 'gray.600', color: 'white' }}
            >
              {item.group}
            </MenuButton>
            <MenuList bg="gray.900">
              {item.links.map((link) => (
                <MenuItem
                  key={link.name}
                  as={RouterLink}
                  to={link.path}
                  bg="gray.900"
                  color="white"
                  _hover={{ bg: 'gray.600' }}
                >
                  {link.name}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        );
      }
      return (
        <Link
          key={item.name}
          as={RouterLink}
          to={item.path}
          p={2}
          rounded="md"
          _hover={{ bg: 'gray.600' }}
          color="white"
        >
          {item.name}
        </Link>
      );
    });
  };

  return (
    <Box bg="gray.900" px={4}>
      <Flex h={16} alignItems="center" justifyContent="space-between">
        <IconButton
          size="md"
          icon={<HamburgerIcon />}
          aria-label="Open Menu"
          display={{ md: 'none' }}
          onClick={onOpen}
        />

        <Box>Logo</Box>

        <Stack direction="row" spacing={4} display={{ base: 'none', md: 'flex' }} alignItems="center">
          {renderDesktopLinks(Links)}
          {isAuthenticated && (
            <Button onClick={handleLogout}>Logout</Button>
          )}
        </Stack>
      </Flex>

      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menu</DrawerHeader>
          <DrawerBody>
            <Stack spacing={4}>
              {renderLinks(Links, onClose)}
              {isAuthenticated && (
                <Button onClick={handleLogout}>Logout</Button>
              )}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}

export default Navbar; 