import { useState, useEffect } from 'react';
import {
    Box,
    Stack,
    Select,
    useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import DataTable from 'react-data-table-component';

function RefiraTransactions() {
    const [transactions, setTransactions] = useState([]);
    const [refiras, setRefiras] = useState([]);
    const [selectedRefira, setSelectedRefira] = useState('');
    const toast = useToast();

    const fetchRefiras = async () => {
        try {
            const token = localStorage.getItem('token');
            const headers = {
                'Authorization': `Basic ${token}`
            };
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v2/refira`, { headers });
            setRefiras(Array.isArray(response.data.data.items) ? response.data.data.items : []);
        } catch (error) {
            console.error('Error fetching refiras:', error);
            toast({
                title: 'Error fetching refiras',
                status: 'error',
                duration: 3000,
            });
        }
    };

    const fetchTransactions = async (refiraId) => {
        if (!refiraId) return;

        try {
            const token = localStorage.getItem('token');
            const headers = {
                'Authorization': `Basic ${token}`
            };
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/api/v2/refira/${refiraId}/transactions`,
                { headers }
            );
            setTransactions(Array.isArray(response.data.data.items) ? response.data.data.items : []);
        } catch (error) {
            console.error('Error fetching transactions:', error);
            setTransactions([]);
            toast({
                title: 'Error fetching transactions',
                status: 'error',
                duration: 3000,
            });
        }
    };

    useEffect(() => {
        fetchRefiras();
    }, []);

    useEffect(() => {
        if (selectedRefira) {
            fetchTransactions(selectedRefira);
        }
    }, [selectedRefira]);

    const columns = [
        {
            name: 'Refira ID',
            selector: row => row.refira_id,
            sortable: true,
        },
        {
            name: 'Agent ID',
            selector: row => row.agent_id,
            sortable: true,
        },
        {
            name: 'Agent Name',
            selector: row => row.agent_name,
            sortable: true,
        },
        {
            name: 'Agent Phone',
            selector: row => row.agent_phone,
            sortable: true,
        },
        {
            name: 'Agent Referral Code',
            selector: row => row.agent_referral_code,
            sortable: true,
        },
        {
            name: 'Customer ID',
            selector: row => row.customer_id,
            sortable: true,
        },
        {
            name: 'Customer Name',
            selector: row => row.customer_name,
            sortable: true,
        },
        {
            name: 'Customer Phone',
            selector: row => row.customer_phone,
            sortable: true,
        },
        {
            name: 'Voucher ID',
            selector: row => row.voucher_id,
            sortable: true,
        },
        {
            name: 'Voucher Code',
            selector: row => row.voucher_code,
            sortable: true,
        },
        {
            name: 'Agent Amount',
            selector: row => row.agent_amount,
            sortable: true,
            format: row => `Rp ${row.agent_amount?.toLocaleString() || 0}`,
        },
        {
            name: 'Customer Amount',
            selector: row => row.customer_amount,
            sortable: true,
            format: row => `Rp ${row.customer_amount?.toLocaleString() || 0}`,
        },
        {
            name: 'Ref. TrxID',
            selector: row => row.reff_trxid,
            sortable: true,
        },
        {
            name: 'Branch Name',
            selector: row => row.branch_name,
            sortable: true,
        },
        {
            name: 'Branch Code',
            selector: row => row.branch_code,
            sortable: true,
        },
        {
            name: 'Store Name',
            selector: row => row.store_name,
            sortable: true,
        },
        {
            name: 'Store Code',
            selector: row => row.store_code,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
        },
        {
            name: 'Referral At',
            selector: row => row.referral_at,
            sortable: true,
            format: row => new Date(row.referral_at).toLocaleString(),
        },
        {
            name: 'Redeem At',
            selector: row => row.redeem_at,
            sortable: true,
            format: row => row.redeem_at ? new Date(row.redeem_at).toLocaleString() : '-',
        },
        {
            name: 'Created At',
            selector: row => row.created_at,
            sortable: true,
            format: row => new Date(row.created_at).toLocaleString(),
        },
        {
            name: 'Updated At',
            selector: row => row.updated_at,
            sortable: true,
            format: row => row.updated_at ? new Date(row.updated_at).toLocaleString() : '-',
        },
    ];

    return (
        <Box p={4}>
            <Stack direction="row" spacing={4} mb={4}>
                <Select
                    placeholder="Select Refira"
                    value={selectedRefira}
                    onChange={(e) => setSelectedRefira(e.target.value)}
                    maxW="300px"
                >
                    {refiras.map(refira => (
                        <option key={refira._id} value={refira._id}>
                            {refira.name}
                        </option>
                    ))}
                </Select>
            </Stack>

            <DataTable
                columns={columns}
                data={transactions}
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                striped
                highlightOnHover
                responsive
                fixedHeader
                fixedHeaderScrollHeight="calc(100vh - 300px)"
                dense
            />
        </Box>
    );
}

export default RefiraTransactions; 