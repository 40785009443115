import { useState, useEffect } from 'react';
import {
    Box,
    Button,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    useDisclosure,
    Select,
    Stack,
    RadioGroup,
    Radio,
} from '@chakra-ui/react';
import axios from 'axios';
import DataTable from 'react-data-table-component';

function RefiraAgents() {
    const [agents, setAgents] = useState([]);
    const [refiras, setRefiras] = useState([]);
    const [selectedRefira, setSelectedRefira] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [newAgent, setNewAgent] = useState({
        refira_id: '',
        name: '',
        nik: '',
        company: '',
        division: '',
        position: '',
        phone: '',
        referral_code: '',
        status: true,
        subscribe_at: '',
    });

    const fetchRefiras = async () => {
        try {
            const token = localStorage.getItem('token');
            const headers = { 'Authorization': `Basic ${token}` };
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v2/refira`, { headers });
            setRefiras(Array.isArray(response.data.data.items) ? response.data.data.items : []);
        } catch (error) {
            console.error('Error fetching refiras:', error);
            toast({
                title: 'Error fetching refiras',
                status: 'error',
                duration: 3000,
            });
        }
    };

    const fetchAgents = async (refiraId) => {
        if (!refiraId) return;

        try {
            const token = localStorage.getItem('token');
            const headers = { 'Authorization': `Basic ${token}` };
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/api/v2/refira/${refiraId}/agents`,
                { headers }
            );
            setAgents(Array.isArray(response.data.data.items) ? response.data.data.items : []);
        } catch (error) {
            console.error('Error fetching agents:', error);
            setAgents([]);
            toast({
                title: 'Error fetching agents',
                status: 'error',
                duration: 3000,
            });
        }
    };

    const handleBulkUpload = async (e) => {
        e.preventDefault();
        if (!selectedFile || !selectedRefira) {
            toast({
                title: 'Please select a file and refira',
                status: 'error',
                duration: 3000,
            });
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const token = localStorage.getItem('token');
            const headers = {
                'Authorization': `Basic ${token}`,
                'Content-Type': 'multipart/form-data',
            };

            await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/api/v2/refira/${selectedRefira}/agents/bulk`,
                formData,
                { headers }
            );

            toast({
                title: 'Agents uploaded successfully',
                status: 'success',
                duration: 3000,
            });

            fetchAgents(selectedRefira);
            onClose();
        } catch (error) {
            toast({
                title: 'Error uploading agents',
                description: error.response?.data?.message || 'Something went wrong',
                status: 'error',
                duration: 3000,
            });
        }
    };

    const handleCreateAgent = async (e) => {
        e.preventDefault();

        try {
            const token = localStorage.getItem('token');
            const headers = {
                'Authorization': `Basic ${token}`,
                'Content-Type': 'application/json',
            };

            await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/api/v2/refira-agent`,
                { ...newAgent, status: Boolean(newAgent.status) },
                { headers }
            );

            toast({
                title: 'Agent created successfully',
                status: 'success',
                duration: 3000,
            });

            setIsCreateModalOpen(false);
            if (selectedRefira) {
                fetchAgents(selectedRefira);
            }

            setNewAgent({
                refira_id: '',
                name: '',
                nik: '',
                company: '',
                division: '',
                position: '',
                phone: '',
                referral_code: '',
                status: true,
                subscribe_at: '',
            });
        } catch (error) {
            toast({
                title: 'Error creating agent',
                description: error.response?.data?.message || 'Something went wrong',
                status: 'error',
                duration: 3000,
            });
        }
    };

    useEffect(() => {
        fetchRefiras();
    }, []);

    useEffect(() => {
        if (selectedRefira) {
            fetchAgents(selectedRefira);
        }
    }, [selectedRefira]);

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'NIK',
            selector: row => row.nik,
            sortable: true,
        },
        {
            name: 'Company',
            selector: row => row.company,
            sortable: true,
        },
        {
            name: 'Division',
            selector: row => row.division,
            sortable: true,
        },
        {
            name: 'Position',
            selector: row => row.position,
            sortable: true,
        },
        {
            name: 'Phone',
            selector: row => row.phone,
            sortable: true,
        },
        {
            name: 'Referral Code',
            selector: row => row.referral_code,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status ? 'Active' : 'Inactive',
            sortable: true,
        },
        {
            name: 'Subscribe At',
            selector: row => row.subscribe_at,
            sortable: true,
            format: row => row.subscribe_at ? new Date(row.subscribe_at).toLocaleString() : '-',
        },
        {
            name: 'Created At',
            selector: row => row.created_at,
            sortable: true,
            format: row => new Date(row.created_at).toLocaleString(),
        },
        {
            name: 'Updated At',
            selector: row => row.updated_at,
            sortable: true,
            format: row => row.updated_at ? new Date(row.updated_at).toLocaleString() : '-',
        },
    ];

    return (
        <Box p={4}>
            <Stack direction="row" spacing={4} mb={4}>
                <Select
                    placeholder="Select Refira"
                    value={selectedRefira}
                    onChange={(e) => setSelectedRefira(e.target.value)}
                    maxW="300px"
                >
                    {refiras.map(refira => (
                        <option key={refira._id} value={refira._id}>
                            {refira.name}
                        </option>
                    ))}
                </Select>

                <Button colorScheme="blue" onClick={onOpen}>
                    Upload Agents
                </Button>
                <Button colorScheme="green" onClick={() => setIsCreateModalOpen(true)}>
                    Create New Agent
                </Button>
            </Stack>

            <DataTable
                columns={columns}
                data={agents}
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                striped
                highlightOnHover
            />

            {/* Bulk Upload Modal */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Upload Agents</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <form onSubmit={handleBulkUpload}>
                            <Stack spacing={4}>
                                <FormControl isRequired>
                                    <FormLabel>Select Excel File</FormLabel>
                                    <Input
                                        type="file"
                                        accept=".xlsx,.xls"
                                        onChange={(e) => setSelectedFile(e.target.files[0])}
                                    />
                                </FormControl>

                                <Button mt={4} colorScheme="blue" type="submit">
                                    Upload
                                </Button>
                            </Stack>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>

            {/* Create Agent Modal */}
            <Modal isOpen={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)} size="lg">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create New Agent</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <form onSubmit={handleCreateAgent}>
                            <Stack spacing={4}>
                                <FormControl isRequired>
                                    <FormLabel>Refira</FormLabel>
                                    <Select
                                        value={newAgent.refira_id}
                                        onChange={(e) => setNewAgent({
                                            ...newAgent,
                                            refira_id: e.target.value
                                        })}
                                    >
                                        <option value="">Select Refira</option>
                                        {refiras.map(refira => (
                                            <option key={refira._id} value={refira._id}>
                                                {refira.name}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl isRequired>
                                    <FormLabel>Name</FormLabel>
                                    <Input
                                        value={newAgent.name}
                                        onChange={(e) => setNewAgent({
                                            ...newAgent,
                                            name: e.target.value
                                        })}
                                    />
                                </FormControl>

                                <FormControl isRequired>
                                    <FormLabel>NIK</FormLabel>
                                    <Input
                                        value={newAgent.nik}
                                        onChange={(e) => setNewAgent({
                                            ...newAgent,
                                            nik: e.target.value
                                        })}
                                    />
                                </FormControl>

                                <FormControl isRequired>
                                    <FormLabel>Company</FormLabel>
                                    <Input
                                        value={newAgent.company}
                                        onChange={(e) => setNewAgent({
                                            ...newAgent,
                                            company: e.target.value
                                        })}
                                    />
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Division</FormLabel>
                                    <Input
                                        value={newAgent.division}
                                        onChange={(e) => setNewAgent({
                                            ...newAgent,
                                            division: e.target.value
                                        })}
                                    />
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Position</FormLabel>
                                    <Input
                                        value={newAgent.position}
                                        onChange={(e) => setNewAgent({
                                            ...newAgent,
                                            position: e.target.value
                                        })}
                                    />
                                </FormControl>

                                <FormControl isRequired>
                                    <FormLabel>Phone</FormLabel>
                                    <Input
                                        value={newAgent.phone}
                                        onChange={(e) => setNewAgent({
                                            ...newAgent,
                                            phone: e.target.value
                                        })}
                                    />
                                </FormControl>

                                <FormControl isRequired>
                                    <FormLabel>Referral Code</FormLabel>
                                    <Input
                                        value={newAgent.referral_code}
                                        onChange={(e) => setNewAgent({
                                            ...newAgent,
                                            referral_code: e.target.value
                                        })}
                                    />
                                </FormControl>

                                <FormControl isRequired>
                                    <FormLabel>Status</FormLabel>
                                    <RadioGroup
                                        value={newAgent.status.toString()}
                                        onChange={(value) => setNewAgent({
                                            ...newAgent,
                                            status: value === 'true'
                                        })}
                                    >
                                        <Stack direction="row">
                                            <Radio value="true">Active</Radio>
                                            <Radio value="false">Inactive</Radio>
                                        </Stack>
                                    </RadioGroup>
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Subscribe Date</FormLabel>
                                    <Input
                                        type="datetime-local"
                                        value={newAgent.subscribe_at}
                                        onChange={(e) => setNewAgent({
                                            ...newAgent,
                                            subscribe_at: e.target.value
                                        })}
                                    />
                                </FormControl>

                                <Button mt={4} colorScheme="blue" type="submit">
                                    Create Agent
                                </Button>
                            </Stack>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    );
}

export default RefiraAgents; 