import { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    useDisclosure,
    Select,
    Textarea,
    Switch,
    Stack,
} from '@chakra-ui/react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

function Kaddos() {
    const [kaddos, setKaddos] = useState([]);
    const [selectedKaddo, setSelectedKaddo] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const fetchKaddos = async () => {
        try {
            const token = localStorage.getItem('token');

            const headers = {
                'Authorization': `Basic ${token}`
            };

            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v2/kaddos/`, { headers });
            setKaddos(Array.isArray(response.data.data.items) ? response.data.data.items : []);
        } catch (error) {
            console.error('Error fetching kaddos:', error);
            setKaddos([]);
            toast({
                title: 'Error fetching kaddos',
                status: 'error',
                duration: 3000,
            });
        }
    };

    const handleCreate = async (formData) => {
        try {
            const token = localStorage.getItem('token');

            const headers = {
                'Authorization': `Basic ${token}`
            };

            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v2/kaddos`, formData, { headers });
            fetchKaddos();
            onClose();
            toast({
                title: 'Kaddo created successfully',
                status: 'success',
                duration: 3000,
            });
        } catch (error) {
            toast({
                title: 'Error creating kaddo',
                status: 'error',
                duration: 3000,
            });
        }
    };

    const handleUpdate = async (id, formData) => {
        try {
            const token = localStorage.getItem('token');

            const headers = {
                'Authorization': `Basic ${token}`
            };

            await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/api/v2/kaddos/${id}`, formData, { headers });
            fetchKaddos();
            onClose();
            toast({
                title: 'Kaddo updated successfully',
                status: 'success',
                duration: 3000,
            });
        } catch (error) {
            toast({
                title: 'Error updating kaddo',
                status: 'error',
                duration: 3000,
            });
        }
    };

    const handleDelete = async (id) => {
        try {
            const token = localStorage.getItem('token');

            const headers = {
                'Authorization': `Basic ${token}`
            };

            await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/v2/kaddos/${id}`, { headers });
            fetchKaddos();
            toast({
                title: 'Kaddo deleted successfully',
                status: 'success',
                duration: 3000,
            });
        } catch (error) {
            toast({
                title: 'Error deleting kaddo',
                status: 'error',
                duration: 3000,
            });
        }
    };

    useEffect(() => {
        fetchKaddos();
    }, []);

    const columns = [
        {
            name: 'ID',
            selector: row => row._id,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
        },
        {
            name: 'Keyword',
            selector: row => row.keyword,
        },
        {
            name: 'Status',
            selector: row => row.status ? 'Active' : 'Inactive',
            sortable: true,
        },
        {
            name: 'Started At',
            selector: row => row.started_at,
            sortable: true,
        },
        {
            name: 'Ended At',
            selector: row => row.ended_at,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => (
                <>
                    <Button
                        size="sm"
                        colorScheme="yellow"
                        mr={2}
                        onClick={() => {
                            setSelectedKaddo(row);
                            onOpen();
                        }}
                    >
                        Edit
                    </Button>
                    <Button
                        size="sm"
                        colorScheme="red"
                        onClick={() => handleDelete(row.id)}
                    >
                        Delete
                    </Button>
                </>
            ),
        },
    ];

    return (
        <Box p={4}>
            <Button colorScheme="blue" mb={4} onClick={() => {
                setSelectedKaddo(null);
                onOpen();
            }}>
                Create New Kaddo
            </Button>

            <DataTable
                columns={columns}
                data={Array.isArray(kaddos) ? kaddos : []}
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                striped
                highlightOnHover
            />

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        {selectedKaddo ? 'Edit Kaddo' : 'Create New Kaddo'}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <KaddoForm
                            kaddo={selectedKaddo}
                            onSubmit={(formData) => {
                                if (selectedKaddo) {
                                    handleUpdate(selectedKaddo._id, formData);
                                } else {
                                    handleCreate(formData);
                                }
                            }}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    );
}

function KaddoForm({ kaddo, onSubmit }) {
    const [formData, setFormData] = useState({
        name: kaddo?.name || '',
        type: kaddo?.type || 'open',
        voucher_type: kaddo?.voucher_type || 'open',
        keyword: kaddo?.keyword || '',
        image_url: kaddo?.image_url || '',
        message_tnc: kaddo?.message_tnc || '',
        message_redeem: kaddo?.message_redeem || '',
        message_voucher: kaddo?.message_voucher || '',
        image_tutorial: kaddo?.image_tutorial || '',
        message_tutorial: kaddo?.message_tutorial || '',
        qty: kaddo?.qty || 0,
        whatsapp_number: kaddo?.whatsapp_number || '',
        whatsapp_token: kaddo?.whatsapp_token || '',
        status: kaddo?.status ?? true,
        started_at: kaddo?.started_at ? new Date(kaddo.started_at) : new Date(),
        ended_at: kaddo?.ended_at ? new Date(kaddo.ended_at) : new Date(),
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleDateChange = (date, field) => {
        setFormData(prev => ({
            ...prev,
            [field]: date
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Format dates before submitting
        const formattedData = {
            ...formData,
            started_at: formData.started_at.toISOString().slice(0, 19).replace('T', ' '),
            ended_at: formData.ended_at.toISOString().slice(0, 19).replace('T', ' '),
        };
        onSubmit(formattedData);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={4}>
                <FormControl>
                    <FormLabel>Name</FormLabel>
                    <Input
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Type</FormLabel>
                    <Select
                        name="type"
                        value={formData.type}
                        onChange={handleChange}
                    >
                        <option value="open">Open</option>
                        {/* Add other type options as needed */}
                    </Select>
                </FormControl>

                <FormControl>
                    <FormLabel>Voucher Type</FormLabel>
                    <Select
                        name="voucher_type"
                        value={formData.voucher_type}
                        onChange={handleChange}
                    >
                        <option value="open">Open</option>
                        {/* Add other voucher type options as needed */}
                    </Select>
                </FormControl>

                <FormControl>
                    <FormLabel>Keyword</FormLabel>
                    <Input
                        name="keyword"
                        value={formData.keyword}
                        onChange={handleChange}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Image URL</FormLabel>
                    <Input
                        name="image_url"
                        value={formData.image_url}
                        onChange={handleChange}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Terms & Conditions Message</FormLabel>
                    <Textarea
                        name="message_tnc"
                        value={formData.message_tnc}
                        onChange={handleChange}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Redeem Message</FormLabel>
                    <Textarea
                        name="message_redeem"
                        value={formData.message_redeem}
                        onChange={handleChange}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Voucher Message</FormLabel>
                    <Textarea
                        name="message_voucher"
                        value={formData.message_voucher}
                        onChange={handleChange}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Tutorial Image</FormLabel>
                    <Input
                        name="image_tutorial"
                        value={formData.image_tutorial}
                        onChange={handleChange}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Tutorial Message</FormLabel>
                    <Textarea
                        name="message_tutorial"
                        value={formData.message_tutorial}
                        onChange={handleChange}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Quantity</FormLabel>
                    <Input
                        name="qty"
                        type="number"
                        value={formData.qty}
                        onChange={handleChange}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>WhatsApp Number</FormLabel>
                    <Input
                        name="whatsapp_number"
                        value={formData.whatsapp_number}
                        onChange={handleChange}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>WhatsApp Token</FormLabel>
                    <Input
                        name="whatsapp_token"
                        value={formData.whatsapp_token}
                        onChange={handleChange}
                    />
                </FormControl>

                <FormControl display="flex" alignItems="center">
                    <FormLabel mb="0">Status</FormLabel>
                    <Switch
                        name="status"
                        isChecked={formData.status}
                        onChange={handleChange}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Start Date</FormLabel>
                    <DatePicker
                        selected={formData.started_at}
                        onChange={(date) => handleDateChange(date, 'started_at')}
                        showTimeSelect
                        dateFormat="yyyy-MM-dd HH:mm:ss"
                        className="chakra-input css-1kp110w"
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>End Date</FormLabel>
                    <DatePicker
                        selected={formData.ended_at}
                        onChange={(date) => handleDateChange(date, 'ended_at')}
                        showTimeSelect
                        dateFormat="yyyy-MM-dd HH:mm:ss"
                        className="chakra-input css-1kp110w"
                    />
                </FormControl>

                <Button mt={4} colorScheme="blue" type="submit">
                    {kaddo ? 'Update' : 'Create'}
                </Button>
            </Stack>
        </form>
    );
}

export default Kaddos; 